import './App.scss';

import linkedin from './assets/media/linkedin.png';
import github from './assets/media/github.png';
import email from './assets/media/email.png';
import face4 from './assets/pics/face4.jpg';
import aws from './assets/poweredBy01.png';

function App() {
  return (
    <div className="app-wrapper">
      <section>
        <header>
          <div class="header-container">
            <div class="profile-pic-container">
              <img src={ face4 } />
            </div>

            <div class="info">
              <div class="header-info">
                <div class="name-role">
                  <label class="title">Carlo Toribio</label>
                  <label class="subtitle">Cloud | Artificial Intelligence | Full Stack Engineer</label>
                </div>
                <div class="social">
                  <a href="https://www.linkedin.com/in/carlotoribio/" target="_blank">
                    <img class="linkedin" src={ linkedin } />
                  </a>
                  <a href="https://github.com/uncarlo?tab=repositories" target="_blank">
                    <img class="github" src={ github } />
                  </a>
                  <a href="mailto:carlo.toribio.dev@gmail.com" target="_blank">
                    <img class="resume" src={ email } />
                  </a>
                </div>
              </div>

              <div class="about">
                Software engineer based in <b>San Diego, California</b>.
                <br /><br />
                Currently working as a <b>Cloud Enginer</b>, <b>Solutions Architect</b> & <b>Full-Stack Software
                  Developer</b>.
                <br /><br />
                With over 10 years of experience my specialty is back-end and front-end development using a vast
                array of technologies such as: 
                <b>Angular</b>, <b>React</b>,<b>.NET</b>, <b>Java / Springboot</b>, <b>Android</b>, <b>iOS</b>; as
                well as cloud computing
                platforms, such as:
                <b>Microsoft Azure</b> and <b>Amazon Web Services</b>. And in the past few months I've been
                learning <b>Open AI</b> and other <b>LLMs</b>, to train AI chatbots in a wide variety of subjects
                and features.
                <br /><br />
                My passion is designing and developing sofware products, I thrive on building high quality
                and efficient systems, that surpass clients expectations.
              </div>
            </div>
          </div>
        </header>
        <article>
          <div class="footer">
            <div class="footer-border">
            </div>
            <div class="copyright">
              <label>Copyright © 2023 Carlo Toribio</label>
            </div>
            <div class="poweredByContainer">
              <img class="poweredBy" src={ aws } />
            </div>
          </div>
        </article>
      </section>
    </div>
  );
}

export default App;
